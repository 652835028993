.shareModal {
  & .copyField {
    display: flex;

    & p {
      border: 1px solid #455541;
      margin: 0;
      padding: 10px;
      border-radius: 4px 0 0 4px;
      flex-shrink: 60;
      border-right: none;
      font-size: 14px;
      white-space: nowrap;
      font-weight: 600;
    }

    & button {
      width: 90px;
      background-color: var(--colorPrimaryButton);
      border: none;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
    }
  }

  & label {
    margin-top: 2em;
  }

  & .iconWrapper {
    display: flex;
    gap: 12px;
  }
}
